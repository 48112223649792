import React from 'react'
import propTypes from 'prop-types'

const InformationIcon = ({ size }) => (
	<svg
		width={size ? size : 16}
		height={size ? size : 16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="text-green-accent fill-current"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.14279 4.90381C9.14279 5.5211 8.63332 6.01213 7.99993 6.01213C7.38031 6.01213 6.85708 5.5211 6.85708 4.90381C6.85708 4.28651 7.38031 3.80951 7.99993 3.80951C8.63332 3.80951 9.14279 4.28651 9.14279 4.90381ZM7.99381 12.1905C7.50289 12.1905 7.10493 11.7925 7.10493 11.3016V8.06546C7.10493 7.57454 7.5029 7.17657 7.99381 7.17657H8.01982C8.51074 7.17657 8.90871 7.57454 8.90871 8.06546V11.3016C8.90871 11.7925 8.51074 12.1905 8.01982 12.1905H7.99381Z"
		/>
	</svg>
)

InformationIcon.propTypes = {
	size: propTypes.number.isRequired,
}

export default InformationIcon
