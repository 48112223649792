import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import DownloadIcon from '../icons/DownloadIcon'

import OutboundLink from '../OutboundLink'

const CollectionDocument = ({ title, file }) => {
	return (
		<Container>
			<ImageContainer>
				<DownloadIcon size={18} />
			</ImageContainer>
			<Content>
				<Title>{title}</Title>
				<Link
					to={file}
					category="Dokument & Mallar"
					label={title}
					action="Nedladdning"
				>
					Ladda ner
				</Link>
			</Content>
		</Container>
	)
}

const Container = styled.div`
	${tw`flex px-3 py-3 bg-black w-full`};
`

const ImageContainer = styled.div`
	flex: 0 0 2rem;
	width: 2rem;
	height: 2rem;
	${tw`flex items-center justify-center bg-gray-600 rounded-full overflow-hidden mr-3`};
`

const Content = styled.div`
	${tw`flex flex-col flex-1 truncate`};
`

const Title = styled.span`
	${tw`text-white leading-tight truncate font-semibold mb-1`};
`

const Link = styled(OutboundLink)`
	${tw`text-white leading-tight text-sm font-medium truncate mb-1 underline`};
`

CollectionDocument.propTypes = {
	title: propTypes.string.isRequired,
	file: propTypes.string.isRequired,
}

export default CollectionDocument
