import React, { useContext } from 'react'

import Preset from './Preset'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import { Context } from '../store'
import { useState } from 'react'

export default () => {
	const { store } = useContext(Context)
	const { dispatch } = useContext(Context)
	const [suggestions, setSuggestions] = useState([])

	const handleClick = () => {
		dispatch({ type: 'SET_QUERY', payload: suggestions.join() })
		setSuggestions([])
	}

	return (
		<>
			<Container>
				{store.suggestions.map((item, i) => (
					<li className="inline-flex" key={item.search_phrase}>
						<Preset
							title={item.search_phrase}
							suggestions={suggestions}
							setSuggestions={setSuggestions}
						/>
					</li>
				))}
			</Container>
			{suggestions.length > 0 && (
				<GreenButton onClick={() => handleClick()}>
					Visa resultat
				</GreenButton>
			)}
		</>
	)
}

const Container = styled.div`
	${tw`mb-2`};
`

const GreenButton = styled.button`
	${tw`bg-green-accent px-2 py-1 outline-none mr-2 focus:outline-none active:bg-gray-200 rounded mb-2 shadow text-white`}
`
