import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { ChevronDown } from 'react-feather'

const AccordionItem = ({
	title,
	notification,
	children,
	open,
	onClick,
	index,
}) => (
	<Container>
		<Header onClick={() => onClick(index)}>
			<TitleContainer>
				<Title>{title}</Title>
				{notification && (
					<NotificationContainer>
						<NotificationTitle>{notification}</NotificationTitle>
					</NotificationContainer>
				)}
			</TitleContainer>
			<ChevronDown size={18} />
		</Header>
		{open && children}
	</Container>
)

const Container = styled.div`
	${tw`bg-white border-t border-solid border-gray-200`};
`

const Header = styled.button`
	${tw`flex items-center justify-between hover:bg-gray-100 outline-none focus:outline-none leading-none w-full px-3 py-3`};
`

const TitleContainer = styled.div`
	${tw`flex items-center justify-between`};
`

const Title = styled.span`
	${tw`font-semibold text-black mb-1 mr-1`};
`

const NotificationContainer = styled.div`
	width: 18px;
	height: 18px;
	${tw`flex relative items-center justify-center bg-green-accent rounded-full`};
`

const NotificationTitle = styled.span`
	top: 46%;
	left: 48%;
	transform: translate(-50%, -50%);
	${tw`absolute text-white text-xs font-bold leading-none`};
`

AccordionItem.propTypes = {
	title: propTypes.string.isRequired,
	notification: propTypes.string,
	children: propTypes.node.isRequired,
	open: propTypes.bool,
	onClick: propTypes.func,
	index: propTypes.number,
}

export default AccordionItem
