import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { format } from 'date-fns'

import OutboundLink from '../OutboundLink'

const Event = ({ title, date, url }) => {
	return (
		<Container to={url} category="Evenemang" label={title} action="Click">
			<DateBadge>{format(date, 'DD/MM')}</DateBadge>
			<Title>{title}</Title>
		</Container>
	)
}

const Container = styled(OutboundLink)`
	${tw`flex px-3 py-3 bg-black hover:bg-gray-600 w-full leading-none`};
`

const DateBadge = styled.span`
	flex: 0 0 3rem;
	width: 3rem;
	${tw`flex text-sm text-gray-200 whitespace-no-wrap leading-tight mr-3`};
`

const Title = styled.span`
	${tw`text-white flex-1 w-full leading-tight font-semibold truncate`};
`

Event.propTypes = {
	title: propTypes.string.isRequired,
	date: propTypes.string.isRequired,
	url: propTypes.string.isRequired,
}

export default Event
