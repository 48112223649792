import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Transition, animated } from 'react-spring/renderprops'

export default ({ results }) => (
	<Header>
		<Transition
			native
			delay={125}
			items={results}
			from={{ transform: 'translateY(125%) rotate(5deg)' }}
			enter={{ transform: 'translateY(0%) rotate(0deg)' }}
			leave={{ transform: 'translateY(-125%) rotate(-5deg)' }}
		>
			{results =>
				results
					? props => (
							<HeaderInner style={props}>
								<Description>Sökresultat</Description>
								<Title>Dina resultat</Title>
							</HeaderInner>
					  )
					: props => (
							<HeaderInner style={props}>
								<Description>
									Välkommen till Business Halland.
								</Description>
								<Title>Hur kan vi hjälpa dig?</Title>
							</HeaderInner>
					  )
			}
		</Transition>
	</Header>
)

const Header = styled.header`
	${tw`bg-black px-3 relative overflow-hidden`};
	height: 10rem;
`

const HeaderInner = styled(animated.div)`
	${tw`absolute top-0 left-0 w-full pt-16 px-3`};
`

const Description = styled.span`
	${tw`w-full block text-gray-300`};
`

const Title = styled.span`
	${tw`w-full text-white block font-bold text-2xl leading-none`};
`
