import React from 'react'
import propTypes from 'prop-types'

const ContactIcon = ({ size }) => (
	<svg
		width={size ? size : 16}
		height={size ? size : 16}
		viewBox="0 0 16 16"
		className="text-green-accent fill-current"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.765636 8.54408C-0.284396 8.0031 -0.249537 7.19074 0.840375 6.73093L14.041 1.16192C15.1323 0.701527 15.7786 1.25583 15.4826 2.40729L12.6936 13.2578C12.3985 14.406 11.3534 14.8132 10.3576 14.166L8.8208 13.1674C7.95102 12.6021 6.73156 12.8095 6.09676 13.6308L6.24765 13.4356C5.61298 14.2568 4.82593 14.1251 4.49204 13.1483L4.07305 11.9225C3.68938 10.8001 2.52932 9.45273 1.47711 8.91063L0.765636 8.54408ZM5.9253 11.5881L5.79159 11.9896C6.07201 11.1477 6.78996 9.94365 7.3958 9.29833L11.6124 4.80698C12.4233 3.94333 12.2953 3.80085 11.3278 4.48746L4.86408 9.07458C4.61997 9.24783 4.48485 9.61947 4.56127 9.90539L5.00179 11.5535C5.23089 12.4106 5.64517 12.4292 5.9253 11.5881Z"
		/>
	</svg>
)

ContactIcon.propTypes = {
	size: propTypes.number.isRequired,
}

export default ContactIcon
