import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import DownloadIcon from '../icons/DownloadIcon'

import OutboundLink from '../OutboundLink'

const Document = ({ title, file }) => (
	<Container>
		<IconContainer>
			<DownloadIcon size={18} />
		</IconContainer>
		<Content>
			<Title>{title}</Title>
			<Link
				to={file}
				category="Mallar & Övningar"
				label={title}
				action="Nedladdning"
			>
				Ladda ner
			</Link>
		</Content>
	</Container>
)

const Container = styled.div`
	${tw`bg-white w-full list-none flex px-3 py-3 align-bottom`};
`

const IconContainer = styled.div`
	flex: 0 0 2rem;
	width: 2rem;
	height: 2rem;
	${tw`flex items-center justify-center bg-gray-100 rounded-full overflow-hidden mr-3`};
`

const Content = styled.div`
	${tw`flex flex-wrap flex-1`};
`

const Title = styled.span`
	${tw`block w-full leading-tight font-semibold mb-1`};
`

const Link = styled(OutboundLink)`
	${tw`text-black leading-tight text-sm font-medium truncate mb-1 underline`};
`

Document.propTypes = {
	title: propTypes.string.isRequired,
	file: propTypes.string.isRequired,
}

export default Document
