import React, { cloneElement, useState } from 'react'
import propTypes from 'prop-types'

const Accordion = ({ children }) => {
	const [openItemIndex, setOpenItemIndex] = useState(null)

	const onClick = index => {
		if (index === openItemIndex) {
			setOpenItemIndex(null)
			return
		}

		setOpenItemIndex(index)
	}

	let elements = React.Children.toArray(children)

	let withProps = elements.map((child, index) => {
		if (child === 0) {
			return null
		}

		return cloneElement(child, {
			key: index,
			index: index,
			open: index === openItemIndex,
			onClick: onClick,
		})
	})

	return withProps
}

Accordion.propTypes = {
	children: propTypes.node,
}

export default Accordion
