import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import InformationIcon from '../icons/InformationIcon'

import OutboundLink from '../OutboundLink'

const Network = ({ title, description, url }) => (
	<Container to={url} category="Nätverk" label={title} action="Click">
		<IconContainer>
			<InformationIcon size={18} />
		</IconContainer>
		<Content>
			<Title>{title}</Title>
			<Description>{description}</Description>
		</Content>
	</Container>
)

const Container = styled(OutboundLink)`
	${tw`bg-white w-full list-none flex px-3 py-3 align-bottom`};
`

const IconContainer = styled.div`
	flex: 0 0 2rem;
	width: 2rem;
	height: 2rem;
	${tw`flex items-center justify-center bg-gray-100 rounded-full overflow-hidden mr-3`};
`

const Content = styled.div`
	${tw`flex flex-wrap flex-1`};
`

const Title = styled.span`
	${tw`leading-tight font-semibold mb-1`};
`

const Description = styled.span`
	${tw`block w-full text-gray-500 leading-tight text-sm mb-2`};
`

Network.propTypes = {
	title: propTypes.string.isRequired,
	description: propTypes.string.isRequired,
	url: propTypes.string.isRequired,
}

export default Network
