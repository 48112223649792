import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const List = ({ title, primary, children, url }) => {
	return (
		<React.Fragment>
			{title && <ListHeader>{title}</ListHeader>}
			<StyledList primary={primary}>{children}</StyledList>
		</React.Fragment>
	)
}

const ListHeader = styled.div`
	${tw`text-gray-600 mb-2 text-sm font-bold`};
`

const StyledList = styled.ul`
	padding-top: 4px;
	${tw`relative bg-white overflow-hidden rounded shadow mb-4`};

	&:before {
		content: '';
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 4px;
		background: ${props => (props.primary ? '#5A9D64' : '#CACDD7')};
	}
`

List.propTypes = {
	title: propTypes.string,
	primary: propTypes.bool,
	children: propTypes.node,
	url: propTypes.string,
}

export default List
