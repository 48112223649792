import React, { useContext } from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import AnnouncementIcon from './icons/AnnouncementIcon'

import OutboundLink from './OutboundLink'
import { Context } from '../store'

const Announcement = () => {
	const {
		store: {
			announcement: { title, url, description },
		},
	} = useContext(Context)

	return (
		<Container>
			<IconContainer>
				<AnnouncementIcon size={18} />
			</IconContainer>
			<Content>
				<Title>{title}</Title>
				<Description>{description}</Description>
				<Link
					to={url}
					category="Meddelande"
					label={title}
					action="Click"
				>
					Läs mer
				</Link>
			</Content>
		</Container>
	)
}

const Container = styled.div`
	${tw`flex w-full px-3 py-4`};
`

const IconContainer = styled.div`
	flex: 0 0 2rem;
	width: 2rem;
	height: 2rem;
	${tw`flex items-center justify-center bg-gray-100 rounded-full overflow-hidden mr-3`};
`

const Content = styled.div`
	${tw`flex flex-wrap flex-1 block`};
`

const Title = styled.h2`
	${tw`text-lg font-semibold text-black mb-2`};
`

const Description = styled.p`
	${tw`text-gray-500 text-sm mb-2 block`};
`

const Link = styled(OutboundLink)`
	${tw`text-black leading-tight text-sm font-medium underline block`};
`

export default Announcement
