import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import AnnouncementIcon from './icons/AnnouncementIcon'
import { Transition, animated } from 'react-spring/renderprops'

export default ({ onClick, showNotification, position }) => (
	<Dock onClick={onClick} position={position}>
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.62272 14.9246C12.1547 14.9246 15.0179 12.0614 15.0179 8.52946C15.0179 4.9975 12.1547 2.13428 8.62272 2.13428C5.09076 2.13428 2.22754 4.9975 2.22754 8.52946C2.22754 12.0614 5.09076 14.9246 8.62272 14.9246Z"
				stroke="#E8EBF4"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.4648 17.2501L13.1392 13.046"
				stroke="#E8EBF4"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>

		<Transition
			items={showNotification}
			from={{ transform: 'scale(0)' }}
			enter={{ transform: 'scale(1)' }}
			leave={{ transform: 'scale(0)' }}
		>
			{shouldRender =>
				shouldRender &&
				(props => (
					<Notification style={props}>
						<AnnouncementIcon size={18} />
					</Notification>
				))
			}
		</Transition>
	</Dock>
)

const Dock = styled.button`
	${tw`bg-green-accent mt-4 mb-4  shadow-md rounded-full hover:bg-green-accent-dark outline-none focus:outline-none flex items-center justify-center w-16 h-16 text-white`};
	transition: background 0.2s;
	&:focus {
		outline: none;
	}
`
const Notification = styled(animated.div)`
	${tw`absolute text-white bg-gray-200 bottom-0 right-0 rounded-full mb-12 mr-1 flex items-center justify-center font-bold`};
	width: 30px;
	height: 30px;
	font-size: 12px;
`
