import React, { useContext } from 'react'

import List from './List'
import Event from './results/Event'

import { Context } from '../store'

export default () => {
	const {
		store: { events },
	} = useContext(Context)

	return (
		<div>
			<List title="Kommande evenemang">
				{events.map(event => (
					<Event
						date={event.acf.start_date}
						title={event.title.rendered}
						description={event.acf.description}
						cta={'Gå till evenemang'}
						url={event.acf.link}
						key={event.id}
					/>
				))}
			</List>
		</div>
	)
}
