import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import OutboundLink from '../OutboundLink'

const Contact = ({ name, role, img, mail, phone }) => {
	return (
		<Container>
			<ImageContainer>
				<img src={img} alt={name} />
			</ImageContainer>
			<Content>
				<Name>{name}</Name>
				<Role>{role}</Role>
				<Link
					target="_self"
					to={`mailto:${mail}`}
					category="Kontaktperson"
					label={`${name} (Mail)`}
					action="Click"
				>
					{mail}
				</Link>
				<Link
					target="_self"
					to={`tel:${phone}`}
					category="Kontaktperson"
					label={`${name} (Telefon)`}
					action="Click"
				>
					{phone}
				</Link>
			</Content>
		</Container>
	)
}

const Container = styled.div`
	${tw`flex px-3 py-3 bg-black w-full`};
`

const ImageContainer = styled.div`
	flex: 0 0 3rem;
	width: 3rem;
	height: 3rem;
	${tw`bg-gray-600 rounded-full overflow-hidden mr-3`};
`

const Content = styled.div`
	${tw`flex flex-col flex-1 truncate`};
`

const Name = styled.span`
	${tw`text-white leading-tight truncate font-semibold mb-1`};
`

const Role = styled.span`
	${tw`text-gray-200 leading-tight text-sm truncate mb-2`};
`

const Link = styled(OutboundLink)`
	${tw`text-white leading-tight text-sm font-medium truncate mb-1 underline`};
`

Contact.propTypes = {
	name: propTypes.string.isRequired,
	role: propTypes.string.isRequired,
	img: propTypes.string.isRequired,
	mail: propTypes.string,
	phone: propTypes.string,
}

export default Contact
