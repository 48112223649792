/**
 * Sort array of hits from algolia into an object with two
 * properties, [postType] and 'other'.
 *
 * @param {Array} Hits from the algolia search.
 * @param {String} Name of the post type to be separated to it's own property.
 * @returns {Object} Separated object.
 * @example
 *
 * sortHitsByPostType(hits, 'events')
 * // => { events: [{...}], other: [{...}] }
 *
 */

const sortHitsByPostType = (hits, postType) =>
	hits.reduce((result, item, index) => {
		index = item.post_type

		if (index === postType) {
			hasOwnProperty.call(result, postType)
				? result[postType].push(item)
				: (result[postType] = [item])

			return result
		} else {
			hasOwnProperty.call(result, 'other')
				? result['other'].push(item)
				: (result['other'] = [item])
		}

		return result
	}, {})

export default sortHitsByPostType
