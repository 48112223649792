import React, { useContext } from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'

import List from './List'
import SearchSuggestions from './SearchSuggestions'
import Collection from './results/Collection'
import Offer from './results/Offer'
import Contact from './results/Contact'
import Document from './results/Document'
import Network from './results/Network'
import Information from './results/Information'

import isEmpty from '../utils/isEmpty'
import { Context } from '../store'

const OfferContainer = ({ hit }) => (
	<Offer
		title={hit.post_title}
		description={hit.content}
		img={hit.actor_image}
		url={hit.link}
	/>
)

const ContactContainer = ({ hit }) => (
	<Contact
		name={hit.post_title}
		role={hit.role}
		phone={hit.phone}
		mail={hit.email}
	/>
)

const DocumentContainer = ({ hit }) => {
	const url = hit.url ? hit.url : hit.link ? hit.link : hit.file.url
	return <Document title={hit.post_title} file={url} />
}

const InformationContainer = ({ hit }) => (
	<Information
		title={hit.post_title}
		description={hit.content}
		url={hit.link}
	/>
)

const NetworkContainer = ({ hit }) => (
	<Network title={hit.post_title} description={hit.content} url={hit.link} />
)

/**
 * Component to show decided by the 'post_type' of each result.
 */
const COMPONENTS = {
	offer: OfferContainer,
	contact: ContactContainer,
	document: DocumentContainer,
	information: InformationContainer,
	network: NetworkContainer,
}

const SearchResult = () => {
	const {
		store: { result },
	} = useContext(Context)

	return (
		<div>
			{!isEmpty(result) ? (
				<div>
					{result.collection && (
						<List title="Toppresultat" primary={true}>
							{result.collection.map(item => (
								<Collection
									title={item.post_title}
									description={item.description}
									area={item.area}
									img={
										item.images.length > 0
											? item.images.thumbnail.url
											: null
									}
									key={item.post_id}
								>
									{item.post_title}
								</Collection>
							))}
						</List>
					)}
					{result.other && (
						<List title="Sökresultat">
							{result.other.map(item => {
								const Component = COMPONENTS[item.post_type]
								return (
									<Component key={item.post_id} hit={item} />
								)
							})}
						</List>
					)}
				</div>
			) : (
				<div>
					<StyledTitle>
						Din sökning gav inga resultat. Här är några förslag från
						oss.
					</StyledTitle>
					<SearchSuggestions />
				</div>
			)}
		</div>
	)
}

const StyledTitle = styled.p`
	${tw`text-md font-bold text-black mb-2`};
`

export default React.memo(SearchResult)
