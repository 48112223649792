import React from 'react'
import ReactGA from 'react-ga'

const handleOutbound = (category, action, label) => {
	ReactGA.event({
		category: category,
		action: action,
		label: label,
	})
}

export default ({
	children,
	category,
	action,
	label,
	to,
	target,
	...props
}) => (
	<a
		{...props}
		href={to}
		rel="noopener noreferrer"
		target={target ? target : '_blank'}
		onClick={() => handleOutbound(category, action, label)}
	>
		{children}
	</a>
)
