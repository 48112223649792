import React, { useContext } from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import { Transition, animated } from 'react-spring/renderprops'
import { Search, ChevronLeft } from 'react-feather'
import Loader from 'react-loader-spinner'
import { DebounceInput } from 'react-debounce-input'

import { Context } from '../store'

const SearchInput = () => {
	const {
		store: { query, searching },
		dispatch,
	} = useContext(Context)

	const onChange = value => {
		dispatch({ type: 'SET_QUERY', payload: value })
	}

	const clearSearchInput = () => {
		dispatch({ type: 'SET_QUERY', payload: '' })
	}

	return (
		<InputContainer>
			<Transition
				native
				items={query.length > 0}
				from={{ transform: 'scale(0) translateY(-50%) rotate(25deg)' }}
				enter={{ transform: 'scale(1) translateY(-50%) rotate(0deg)' }}
				leave={{ transform: 'scale(0) translateY(-50%) rotate(25deg)' }}
			>
				{query =>
					query &&
					(props => (
						<Button
							style={props}
							onClick={e => clearSearchInput(e)}
						>
							<Transition
								native
								items={searching}
								from={{
									opacity: 0,
									transform:
										'translate(-50%, -50%) scale(0.25)',
								}}
								enter={{
									opacity: 1,
									transform: 'translate(-50%, -50%) scale(1)',
								}}
								leave={{
									opacity: 0,
									transform:
										'translate(-50%, -50%) scale(0.25)',
								}}
							>
								{searching =>
									searching
										? props => (
												<ButtonIconContainer
													style={props}
												>
													<Loader
														type="Oval"
														color="#000"
														height="18"
														width="18"
													/>
												</ButtonIconContainer>
										  )
										: props => (
												<ButtonIconContainer
													style={props}
												>
													<ChevronLeft size={18} />
												</ButtonIconContainer>
										  )
								}
							</Transition>
						</Button>
					))
				}
			</Transition>
			<InputIcon>
				<Search size={18} />
			</InputIcon>
			<Input
				onChange={e => onChange(e.target.value)}
				value={query}
				debounceTimeout={500}
				placeholder="Jag letar efter..."
			/>
		</InputContainer>
	)
}

export default SearchInput

const InputContainer = styled.div`
	${tw`rounded  overflow-hidden h-12 w-full relative bg-gray-200 shadow-lg mb-4`};
`

const Input = styled(DebounceInput)`
	${tw`w-full h-full font-medium pr-4 outline-none focus:outline-none appearance-none `};
	padding-left: 3.15rem;
`

const Button = styled(animated.button)`
	${tw`h-8 w-8 rounded-lg bg-gray-200 appearance-none overflow-hidden absolute outline-none focus:outline-none hover:bg-gray-300 left-0 ml-2 z-10 flex justify-center items-center`};
	transform: translateY(-50%);
	transition: background 0.2s;
	transform-origin: top center;
	top: 50%;
`

const ButtonIconContainer = styled(animated.div)`
	${tw`absolute`};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: center;
`

const InputIcon = styled.div`
	${tw`h-8 w-8 absolute left-0 ml-2 flex justify-center items-center`};
	transform: translateY(-50%);
	top: 50%;
`
