import React from 'react'
import propTypes from 'prop-types'

const AnnouncementIcon = ({ size }) => (
	<svg
		width={size ? size : 16}
		height={size ? size : 16}
		className="text-green-accent fill-current"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M12.5 0C11.6105 0 10.801 0.6215 10.1835 1.6365C7.896 3.1685 4.628 3.5 3 3.5C1.343 3.5 0 4.843 0 6.5C0 8.157 1.343 9.5 3 9.5C4.628 9.5 7.896 9.8315 10.1835 11.3635C10.801 12.3785 11.6105 13 12.5 13C14.433 13 16 10.09 16 6.5C16 2.91 14.433 0 12.5 0ZM12.5 11C12.221 10.911 11.5415 10.06 11.2015 8.4795C12.21 8.3775 13 7.5355 13 6.5C13 5.4645 12.21 4.6225 11.201 4.5205C11.547 2.9135 12.2305 2.0645 12.5 2C12.8375 2.0875 14 3.6065 14 6.5C14 9.3585 12.879 10.879 12.5 11ZM3 10.5C2.776 10.5 2.5575 10.477 2.343 10.4415C2.5445 12.2855 2.862 14.4885 3 15.1355C3.147 15.8235 3.7 16 4 16H6C6.357 16 7.013 15.7395 7 14.945C6.993 14.507 6.4315 12.728 6.193 10.8505C5.049 10.6035 3.9105 10.5 3 10.5Z" />
	</svg>
)

AnnouncementIcon.propTypes = {
	size: propTypes.number.isRequired,
}

export default AnnouncementIcon
