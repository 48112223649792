import React from 'react'
import propTypes from 'prop-types'

const CalendarIcon = ({ size }) => (
	<svg
		width={size ? size : 16}
		height={size ? size : 16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="text-green-accent fill-current"
	>
		<g clipPath="url(#clip0)">
			<path d="M6.53332 2.28872C6.92849 2.28872 7.32367 1.97588 7.42246 1.5807L7.65298 0.708022C7.75177 0.312847 7.52125 0 7.14255 0C6.74737 0 6.35219 0.312847 6.2534 0.708022L6.02288 1.5807C5.92409 1.95941 6.15461 2.28872 6.53332 2.28872Z" />
			<path d="M11.5497 2.28872C11.9449 2.28872 12.3401 1.97588 12.4389 1.5807L12.6694 0.708022C12.7682 0.312847 12.5377 0 12.1589 0C11.7638 0 11.3686 0.312847 11.2698 0.708022L11.0228 1.5807C10.924 1.95941 11.1545 2.28872 11.5497 2.28872Z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.1222 1.00439H13.829C15.0239 1.00439 16 1.9805 16 3.17538V13.4918C16 14.6867 15.0239 15.6628 13.829 15.6628H5.01042C3.81553 15.6628 2.83943 14.6867 2.83943 13.4918V13.0374H1.81283C1.22381 13.0374 0.702096 12.785 0.365508 12.3474C0.0120914 11.9098 -0.0888848 11.304 0.079409 10.6981L2.14942 3.00709C2.43552 1.87952 3.56309 1.00439 4.69066 1.00439H5.43115L5.31335 1.44196C5.21237 1.8122 5.27969 2.19928 5.49847 2.48538C5.71725 2.77148 6.05384 2.92294 6.42408 2.92294C7.11409 2.92294 7.75361 2.41806 7.93873 1.74489L8.14068 1.00439H10.4295L10.3117 1.44196C10.2107 1.8122 10.278 2.19928 10.4968 2.48538C10.6987 2.77148 11.0522 2.92294 11.4224 2.92294C12.0956 2.92294 12.7351 2.41806 12.9202 1.74489L13.1222 1.00439ZM13.829 14.3501C14.3002 14.3501 14.6873 13.963 14.6873 13.4918H14.6705V5.44735L13.1727 11.0347C12.8866 12.1623 11.759 13.0374 10.6314 13.0374H4.15212V13.4918C4.15212 13.963 4.53919 14.3501 5.01042 14.3501H13.829ZM8.19224 6.70954L6.32418 8.19053L5.98759 7.73614C5.76881 7.45004 5.36491 7.38272 5.06198 7.6015C4.77588 7.82028 4.70856 8.22419 4.92734 8.52712L5.66784 9.52005C5.76881 9.67151 5.9371 9.75566 6.1054 9.78932H6.18955C6.34101 9.78932 6.47565 9.73883 6.59345 9.65469L9.00005 7.75297C9.28615 7.53418 9.33664 7.11345 9.10103 6.82735C8.88225 6.52442 8.47834 6.47393 8.19224 6.70954Z"
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

CalendarIcon.propTypes = {
	size: propTypes.number.isRequired,
}

export default CalendarIcon
