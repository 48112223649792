import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { useCookies } from 'react-cookie'

import { Context } from '../store'

const Notification = ({ openWidget }) => {
	const {
		store: {
			announcement: { title, description, image, last_updated },
		},
		dispatch,
	} = useContext(Context)
	// eslint-disable-next-line
	const [cookies, setCookie] = useCookies([])

	const closeNotification = () => {
		setCookie('viewedAnnouncementId', last_updated, {
			path: '/',
			sameSite: 'none',
			secure: true,
		})

		dispatch({ type: 'SET_SHOW_ANNOUNCEMENT', payload: false })
	}

	const readMore = () => {
		setCookie('viewedAnnouncementId', last_updated, {
			path: '/',
			sameSite: 'none',
			secure: true,
		})

		dispatch({ type: 'SET_SHOW_ANNOUNCEMENT', payload: false })

		openWidget()
	}

	useEffect(() => {
		return () =>
			window.parent.postMessage({ type: 'ANNOUNCEMENT_HIDE' }, '*')
	}, [])

	return (
		<Container>
			<Header>
				<HeaderContent>
					<Title>{title}</Title>
					<Description>{description}</Description>
				</HeaderContent>
				<ImageContainer>
					{image && <Image src={image} alt={title} />}
				</ImageContainer>
			</Header>
			<Footer>
				<FooterButton onClick={readMore}>Läs mer</FooterButton>
				<CloseButton onClick={closeNotification}>Stäng</CloseButton>
			</Footer>
		</Container>
	)
}

const Container = styled.div`
	${tw`relative w-full bg-white shadow-lg rounded overflow-hidden`};
	height: 220px;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 4px;
		background-color: #5A9D64;
	}
`

const Footer = styled.div`
	${tw`bg-white absolute bottom-0 left-0 right-0 px-3 pb-4 pt-16`};
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`

const FooterButton = styled.button`
	${tw`bg-black px-5 py-3 text-lg font-semibold rounded-full leading-none text-white inline-block`};
`

const CloseButton = styled.button`
	${tw`text-gray-400 ml-3 inline-block`};
`

const Header = styled.div`
	${tw`flex items-start py-2 w-full overflow-hidden`};
`

const HeaderContent = styled.div`
	flex: 0 0 calc(100% - 3rem);
	${tw`flex flex-wrap px-3 pt-2`};
`

const Title = styled.h2`
	${tw`block w-full  text-xl font-semibold text-black mb-3 mt-1 leading-tight`};
`

const Description = styled.span`
	${tw`block text-gray-500 mb-2 leading-snug`};
`

const ImageContainer = styled.div`
	flex: 0 0 6rem;
	width: 6rem;
	height: 6rem;
	${tw`relative bg-gray-200 rounded-full overflow-hidden border-8 border-solid border-gray-100`};
`

const Image = styled.img`
	${tw`absolute w-full h-full`};
`

export default Notification
