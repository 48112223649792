import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import axios from 'axios'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import { Accordion, AccordionItem } from '../accordion'
import CollectionEvent from './CollectionEvent'
import CollectionOffer from './CollectionOffer'
import CollectionContact from './CollectionContact'
import CollectionDocument from './CollectionDocument'
import CollectionNetwork from './CollectionNetwork'
import CollectionInformation from './CollectionInformation'

const Collection = ({ title, description, area, img }) => {
	const [offers, setOffers] = useState([])
	const [contacts, setContacts] = useState([])
	const [events, setEvents] = useState([])
	const [documents, setDocuments] = useState([])
	const [information, setInformation] = useState([])
	const [networks, setNetworks] = useState([])

	useEffect(() => {
		const getOffers = async () => {
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/offer?area=${area}`
				)
				setOffers(result.data)
			} catch (error) {
				console.log(error)
			}
		}

		const getContacts = async () => {
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/contact?area=${area}`
				)
				setContacts(result.data)
			} catch (error) {
				console.log(error)
			}
		}

		const getEvents = async () => {
			try {
				const now = new Date().toISOString().substring(0, 19)
				const filter = `filter[meta_key]=start_date&filter[meta_value]=${now}&filter[meta_compare]=>`
				const result = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/event?area=${area}&${filter}`
				)
				setEvents(result.data)
			} catch (error) {
				console.log(error)
			}
		}

		const getDocuments = async () => {
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/document?area=${area}`
				)
				setDocuments(result.data)
			} catch (error) {
				console.log(error)
			}
		}

		const getNetworks = async () => {
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/network?area=${area}`
				)
				setNetworks(result.data)
			} catch (error) {
				console.log(error)
			}
		}

		const getInformation = async () => {
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/information?area=${area}`
				)
				setInformation(result.data)
			} catch (error) {
				console.log(error)
			}
		}

		getEvents()
		getOffers()
		getInformation()
		getContacts()
		getDocuments()
		getNetworks()
	}, [area])

	return (
		<Container>
			<Header>
				<HeaderContent>
					<Title>{title}</Title>
					<Description>{description}</Description>
				</HeaderContent>
				<ImageContainer>
					{img && <Image src={img} alt={title} />}
				</ImageContainer>
			</Header>
			<Accordion>
				{!!events.length && (
					<AccordionItem
						title="Evenemang"
						notification={events.length.toString()}
					>
						<ul>
							{events.map(item => (
								<li key={item.id}>
									<CollectionEvent
										title={item.title.rendered}
										date={item.acf.start_date}
										url={item.acf.link}
									/>
								</li>
							))}
						</ul>
					</AccordionItem>
				)}
				{!!offers.length && (
					<AccordionItem
						title="Erbjudanden"
						notification={offers.length.toString()}
					>
						<ul>
							{offers.map(item => (
								<li key={item.id}>
									<CollectionOffer
										title={item.title.rendered}
										description={item.content.rendered}
										url={item.acf.link}
									/>
								</li>
							))}
						</ul>
					</AccordionItem>
				)}

				{!!information.length && (
					<AccordionItem
						title="Information"
						notification={information.length.toString()}
					>
						<ul>
							{information.map(item => (
								<li key={item.id}>
									<CollectionInformation
										title={item.title.rendered}
										description={item.content.rendered}
										url={item.acf.link}
									/>
								</li>
							))}
						</ul>
					</AccordionItem>
				)}
				{!!documents.length && (
					<AccordionItem
						title="Mallar och övningar"
						notification={documents.length.toString()}
					>
						<ul>
							{documents.map(item => (
								<li key={item.id}>
									<CollectionDocument
										title={item.title.rendered}
										file={
											item.acf.url
												? item.acf.url
												: item.acf.file.url
										}
									/>
								</li>
							))}
						</ul>
					</AccordionItem>
				)}

				{!!networks.length && (
					<AccordionItem
						title="Nätverk"
						notification={networks.length.toString()}
					>
						<ul>
							{networks.map(item => (
								<li key={item.id}>
									<CollectionNetwork
										title={item.title.rendered}
										description={item.content.rendered}
										url={item.acf.link}
									/>
								</li>
							))}
						</ul>
					</AccordionItem>
				)}

				{!!contacts.length && (
					<AccordionItem
						title="Kontaktpersoner"
						notification={contacts.length.toString()}
					>
						<ul>
							{contacts.map(item => (
								<li key={item.id}>
									<CollectionContact
										name={item.title.rendered}
										role={item.acf.role}
										img={item.acf.profile_image}
										phone={item.acf.phone}
										mail={item.acf.email}
									/>
								</li>
							))}
						</ul>
					</AccordionItem>
				)}
			</Accordion>
		</Container>
	)
}

const Container = styled.div`
	${tw`bg-white`};
`

const ImageContainer = styled.div`
	flex: 0 0 6rem;
	width: 6rem;
	height: 6rem;
	${tw`relative bg-gray-200 rounded-full overflow-hidden border-8 border-solid border-gray-100`};
`

const Image = styled.img`
	${tw`absolute w-full h-full`};
`

const Header = styled.div`
	${tw`flex items-start py-2 w-full overflow-hidden`};
`

const HeaderContent = styled.div`
	flex: 0 0 calc(100% - 3rem);
	${tw`flex flex-col flex-wrap px-3`};
`

const Title = styled.h2`
	${tw`text-lg font-semibold text-black mb-1 mt-1 leading-tight`};
`

const Description = styled.span`
	${tw`inline-block text-gray-500 mb-2 leading-snug`};
`

Collection.propTypes = {
	title: propTypes.string.isRequired,
	description: propTypes.string.isRequired,
	area: propTypes.number.isRequired,
	img: propTypes.string,
}

export default Collection
