import React, { useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import axios from 'axios'
// import algoliasearch from 'algoliasearch'
import { Transition, animated } from 'react-spring/renderprops'

import Announcement from './Announcement'
import Header from './Header'
import List from './List'
import SearchInput from './SearchInput'
import SearchResult from './SearchResult'
import SearchSuggestions from './SearchSuggestions'
import Events from './Events'

import { Context } from '../store'
import sortHitsByPostType from '../utils/sortHitsByPostType'

// const client = algoliasearch(
// 	process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
// 	process.env.REACT_APP_ALGOLIA_API_KEY
// )

// const index = client.initIndex('wp_searchable_posts')

const Content = () => {
	const {
		store: { query, showAnnouncement, announcement, events },
		dispatch,
	} = useContext(Context)

	useEffect(() => {
		// Get suggestions
		const getSuggestions = async () => {
			try {
				// prettier-ignore
				const suggestions = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/acf/v3/options/search-phrase`)
				dispatch({
					type: 'SET_SUGGESTIONS',
					payload: suggestions.data.acf.search_phrases,
				})
			} catch (error) {
				console.log(error)
			}
		}

		getSuggestions()
	}, [dispatch])

	// Resize iframe on unmount
	useEffect(() => {
		return () => {
			window.parent.postMessage({ type: 'WIDGET_CLOSE' }, '*')
			window.postMessage({ type: 'WIDGET_CLOSE' }, '*')
		}
	}, [])

	useEffect(() => {
		// Get events
		const now = new Date().toISOString().substring(0, 19)
		const filter = `filter[meta_key]=start_date&filter[meta_value]=${now}&filter[meta_compare]=>`

		const getEvents = async () => {
			try {
				// prettier-ignore
				const events = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/event?${filter}`)
				dispatch({
					type: 'SET_EVENTS',
					payload: events.data,
				})
			} catch (error) {
				console.log(error)
			}
		}

		getEvents()
	}, [dispatch])

	// Search
	useEffect(() => {
		if (!query) {
			return
		}

		const search = async () => {
			try {
				// const result = await index.search({ query: query })
				const information = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/information/?per_page=100`
				)
				const network = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/network/?per_page=100`
				)
				const document = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/document/?per_page=100`
				)
				const offer = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/offer/?per_page=100`
				)
				const areas = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/area/?per_page=100`
				)

				const mixedResults = Array.prototype.concat.apply(
					[],
					[information.data, network.data, document.data, offer.data]
				)

				const queryItems = query.split(',')

				const filtered = mixedResults.filter(post => {
					const areaNames = post.area.map(el => {
						const areaName = areas.data.find(x => x.id === el).name
						return areaName
					})
					for (let index = 0; index < queryItems.length; index++) {
						if (
							JSON.stringify(areaNames)
								.toLowerCase()
								.match(queryItems[index].toLowerCase())
						) {
							return post
						}
					}
					return JSON.stringify(post)
						.toLowerCase()
						.match(query.toLowerCase())
				})
				const mappedFiltered = filtered.map(el => {
					const mappedObj = {
						id: el.id,
						post_id: el.id,
						title: el.title.rendered
							? el.title.rendered.replace(/<[^>]*>?/gm, '')
							: '',
						post_title: el.title.rendered
							? el.title.rendered.replace(/<[^>]*>?/gm, '')
							: '',
						content: el.content.rendered
							? el.content.rendered.replace(/<[^>]*>?/gm, '')
							: '',
						link: el.acf.link ? el.acf.link : '',
						pdf_link: el.acf.pdf_link ? el.acf.pdf_link : '',
						post_type: el.type,
						url: el.acf.url ? el.acf.url : '',
					}
					return mappedObj
				})

				mappedFiltered.length
					? ReactGA.pageview(
							`${window.location.pathname}/bh-search?bhq=${query}&cat=with-result`
					  )
					: ReactGA.pageview(
							`${window.location.pathname}/bh-search?bhq=${query}&cat=no-result`
					  )

				const sorted = sortHitsByPostType(mappedFiltered, 'collection')
				// const sortedHits = sortHitsByPostType(result.hits, 'collection')

				dispatch({ type: 'SET_RESULT', payload: sorted })
				dispatch({ type: 'SET_SEARCHING', payload: false })
			} catch (error) {
				console.log(error)
				console.log(error.debugData)
				dispatch({ type: 'SET_RESULT', payload: {} })
				dispatch({ type: 'SET_SEARCHING', payload: false })
			}
		}

		search()
	}, [query, dispatch])

	return (
		<Container scroll={!showAnnouncement}>
			<Header results={query !== ''} />
			<ContainerInner>
				<SearchInput />
				<AnimationPlaceholder>
					<Transition
						native
						delay={125}
						items={query !== ''}
						from={{
							opacity: 0,
							transform: 'translateX(40px)',
						}}
						enter={{
							opacity: 1,
							transform: 'translateX(0px)',
						}}
						leave={{
							opacity: 0,
							transform: 'translateX(40px)',
						}}
					>
						{query => props =>
							query ? (
								<AnimationContainer style={props}>
									<SearchResult />
								</AnimationContainer>
							) : (
								<AnimationContainer style={props}>
									<SearchSuggestions />
									{announcement && (
										<List primary>
											<li>
												<Announcement />
											</li>
										</List>
									)}
									{events && events.length > 0 && <Events />}
								</AnimationContainer>
							)}
					</Transition>
				</AnimationPlaceholder>
			</ContainerInner>
		</Container>
	)
}

const AnimationPlaceholder = styled.div`
	${tw`relative`};
`

const AnimationContainer = styled(animated.div)`
	${tw`w-full absolute top-0 left-0 right-0`}
`

const ContainerInner = styled.div`
	${tw`px-3 -mt-8`};
`

const Container = styled.div`
	${tw`bg-gray-100 w-full h-full relative shadow-md overflow-x-hidden rounded sm:rounded scrolling-touch`};
	overflow-y: ${props => (props.scroll ? 'scroll' : 'hidden')};
`

export default Content
