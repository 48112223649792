import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { format } from 'date-fns'
import CalendarIcon from '../icons/CalendarIcon'

import OutboundLink from '../OutboundLink'

const Event = ({ title, date, url }) => (
	<Container category="Evenemang" label={title} action="Click">
		<IconContainer>
			<CalendarIcon size={18} />
		</IconContainer>
		<Content>
			<Title dangerouslySetInnerHTML={{ __html: title }} />
			<DateBadge>{format(date, 'DD MMMM')}</DateBadge>
			<Link to={url}>Gå till anmälan</Link>
		</Content>
	</Container>
)

const Container = styled.div`
	${tw`flex w-full px-3 py-4`};
`

const IconContainer = styled.div`
	flex: 0 0 2rem;
	width: 2rem;
	height: 2rem;
	${tw`flex items-center justify-center bg-gray-100 rounded-full overflow-hidden mr-3`};
`

const Content = styled.div`
	${tw`flex flex-wrap flex-1`};
`

const Title = styled.h2`
	${tw`text-lg font-semibold leading-normal text-black mb-2`};
`

const DateBadge = styled.span`
	${tw`block w-full font-semibold text-gray-500 text-sm mb-3`};
`

const Link = styled(OutboundLink)`
	${tw`text-black leading-tight text-sm font-medium truncate mb-1 underline`};
`

Event.propTypes = {
	title: propTypes.string.isRequired,
	date: propTypes.string.isRequired,
	url: propTypes.string.isRequired,
}

export default Event
