/**
 * Check if an object is empty or not.
 *
 * @param {Object} An object.
 * @returns {Boolean} A boolean indicating if the object is empty or not.
 * @example
 *
 * isEmpty({})
 * // => true
 *
 */
const isEmpty = obj =>
	Object.entries(obj).length === 0 && obj.constructor === Object

export default isEmpty
