import React from 'react'
import propTypes from 'prop-types'

const OfferIcon = ({ size }) => (
	<svg
		width={size ? size : 16}
		height={size ? size : 16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="text-green-accent fill-current"
	>
		<path d="M14.806 3.515a.167.167 0 01-.098.152L12.329 4.75a.333.333 0 01-.276 0L5.587 1.803a.167.167 0 010-.305L7.733.565a.667.667 0 01.534 0l6.44 2.798c.06.027.1.086.1.152zM10.746 5.362a.167.167 0 01-.097.152l-2.194.997a.333.333 0 01-.266.004l-6.86-2.859a.167.167 0 010-.307l2.544-1.104a.333.333 0 01.271 0l6.505 2.966c.06.027.097.086.097.151zM.564 4.42a.167.167 0 00-.23.154v7.43c0 .27.16.512.41.616L7.6 15.478a.167.167 0 00.23-.154V7.56a.167.167 0 00-.102-.154L.564 4.42zM8.91 15.436a.167.167 0 01-.077-.14l.002-7.751c0-.066.039-.125.098-.152l6.498-2.954a.166.166 0 01.236.152v7.429a.667.667 0 01-.391.607l-6.207 2.821a.167.167 0 01-.16-.012z"></path>
	</svg>
)

OfferIcon.propTypes = {
	size: propTypes.number.isRequired,
}

export default OfferIcon
