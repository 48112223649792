import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import './styles/index.css'
import App from './App'

const root = document.getElementById('root')

// Listen for messages from the parent
// window.addEventListener(
// 	'message',
// 	event => {
// 		if (event.data.type !== 'WIDGET_MOUNT') {
// 			return
// 		}

// 		ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
// 		ReactGA.pageview(window.location.pathname + window.location.search)
// 		ReactDOM.render(<App options={event.data.options} />, root)
// 	},
// 	false
// )

		ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
		ReactGA.pageview(window.location.pathname + window.location.search)

ReactDOM.render(<App options={{position: "left" }} />, root)


// Tell parent that the widget is ready to mount
window.parent.postMessage({ type: 'WIDGET_READY' }, '*')
