import React, { useEffect, useState, useReducer, useCallback } from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Transition, animated } from 'react-spring/renderprops'
import axios from 'axios'
import { useCookies, CookiesProvider } from 'react-cookie'
import ReactGA from 'react-ga'

import Notification from './components/Notification'
import Dock from './components/Dock'
import Widget from './components/Widget'

import { Context, initialState, reducer } from './store'

const App = ({ position, options }) => {
	const [open, setOpen] = useState(false)
	const [openedOnce, setOpenedOnce] = useState(false)
	const [hasSeenAnnouncement, setHasSeenAnnouncement] = useState(true)
	const [store, dispatch] = useReducer(reducer, initialState)
	const [cookies] = useCookies([])

	const toggleWidget = () => {
		// Post message to for iframe to expand widget area if widget will open
		if (!open) {
			return openWidget()
		}

		closeWidget()
	}

	useEffect(() => {
		const hasLocalStorage = typeof localStorage !== 'undefined'

		setHasSeenAnnouncement(
			hasLocalStorage && !!localStorage.getItem('hasSeenAnnouncement')
		)

		hasLocalStorage && localStorage.setItem('hasSeenAnnouncement', true)
	}, [])

	const openWidget = useCallback(() => {
		// Post message to for iframe to expand widget area if widget will open
		window.parent.postMessage({ type: 'WIDGET_OPEN' }, '*')

		if (!openedOnce) {
			dispatch({ type: 'SET_SHOW_ANNOUNCEMENT', payload: false })
			setOpenedOnce(true)
			ReactGA.event({
				category: 'User',
				action: 'Opened Widget',
			})
		}

		setOpen(true)
	}, [openedOnce])

	const closeWidget = useCallback(() => {
		setOpen(false)
	}, [])

	useEffect(() => {
		window.addEventListener('message', event => {
			if (event.data.type === 'WIDGET_OPEN') {
				openWidget()
				return
			}

			if (event.data.type === 'WIDGET_CLOSE') {
				closeWidget()
				return
			}
		})
	}, [openWidget, closeWidget])

	useEffect(() => {
		const getAnnouncement = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/wp-json/acf/v3/options/announcement`
				)

				// prettier-ignore
				if (!response.data.acf || !response.data.acf.display_announcement) {
					return
				}

				dispatch({
					type: 'SET_ANNOUNCEMENT',
					payload: response.data.acf,
				})

				const announcementId = parseInt(response.data.acf.last_updated)
				const viewedAnnouncementId = parseInt(
					cookies.viewedAnnouncementId
				)

				// User has seen the notification
				if (announcementId === viewedAnnouncementId) {
					return
				}

				// Let the host site know there is a notification
				window.parent.postMessage({ type: 'ANNOUNCEMENT_SHOW' }, '*')

				dispatch({
					type: 'SET_SHOW_ANNOUNCEMENT',
					payload: true,
				})
			} catch (error) {
				console.log(error)
			}
		}

		getAnnouncement()
	}, [cookies.viewedAnnouncementId])

	return (
		<Context.Provider value={{ store, dispatch }}>
			<CookiesProvider>
				<Container position={options.position}>
					<WidgetContainer>
						<Transition
							native
							unique
							items={store.showAnnouncement}
							from={{
								transform: 'scale(0.8) translateY(20px)',
								opacity: 0,
								position: 'absolute',
								left: 0,
							}}
							enter={{
								transform: 'scale(1) translateY(0)',
								opacity: 1,
								position: 'absolute',
								left: 0,
							}}
							leave={{
								transform: 'scale(0.8) translateY(20px)',
								opacity: 0,
								position: 'absolute',
								left: 0,
							}}
						>
							{show =>
								show &&
								!hasSeenAnnouncement &&
								(props => (
									<AnimationContainer style={props}>
										<Notification openWidget={openWidget} />
									</AnimationContainer>
								))
							}
						</Transition>

						<Transition
							native
							unique
							items={open}
							from={{
								transform: 'scale(0.8) translateY(75px)',
								opacity: 0,
							}}
							enter={{
								transform: 'scale(1) translateY(0)',
								opacity: 1,
							}}
							leave={{
								transform: 'scale(0.8) translateY(75px)',
								opacity: 0,
							}}
						>
							{open =>
								open &&
								(props => (
									<AnimationContainer style={props}>
										<Widget />
									</AnimationContainer>
								))
							}
						</Transition>
					</WidgetContainer>
					<DockContainer position={options.position}>
						<Dock onClick={toggleWidget} />
					</DockContainer>
				</Container>
			</CookiesProvider>
		</Context.Provider>
	)
}

const DockContainer = styled.div`
	${tw`flex items-center`};
	${props =>
		props.position === 'left' ? tw`flex-row-reverse` : tw`flex-row`}
`

const WidgetContainer = styled.div`
	${tw`flex-1 w-full relative`};
`

const AnimationContainer = styled(animated.div)`
	${tw`w-full h-full flex items-end`};
`

const Container = styled.div`
	${tw`flex flex-col font-sans px-4`};
	align-items: flex-end;
	height: 100vh;
	width: 100vw;
`

export default App
