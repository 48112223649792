import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { useState } from 'react'

const Preset = ({ title, suggestions, setSuggestions }) => {
	const [active, setActive] = useState(false)

	const handleClick = () => {
		const tmpSuggestions = [...suggestions]
		if (!tmpSuggestions.includes(title)) {
			tmpSuggestions.push(title)
		} else {
			tmpSuggestions.splice(tmpSuggestions.indexOf(title), 1)
		}
		setSuggestions(tmpSuggestions)
		setActive(!active)
	}

	return (
		<>
			{active ? (
				<GreenContainer onClick={() => handleClick()}>
					<GreenTitle>{title}</GreenTitle>
				</GreenContainer>
			) : (
				<Container onClick={() => handleClick()}>
					<Title>{title}</Title>
				</Container>
			)}
		</>
	)
}

const Container = styled.button`
	${tw`bg-white px-2 py-1 outline-none mr-2 focus:outline-none active:bg-gray-200 rounded mb-2 shadow`};
`

const GreenContainer = styled.button`
	${tw`bg-green-accent px-2 py-1 outline-none mr-2 focus:outline-none active:bg-gray-200 rounded mb-2 shadow`};
`

const Title = styled.span`
	${tw`font-bold flex-1 leading-snug text-sm text-black`};
`

const GreenTitle = styled.span`
	${tw`font-bold flex-1 leading-snug text-sm text-white`};
`

Preset.propTypes = {
	title: propTypes.string.isRequired,
	suggestions: propTypes.array.isRequired,
	setSuggestions: propTypes.func.isRequired,
}

export default Preset
