import React from 'react'

export const initialState = {
	query: '',
	searching: false,
	suggestions: [],
	announcement: null,
	showAnnouncement: false,
	result: {},
}

export const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_QUERY':
			return { ...state, query: action.payload }
		case 'SET_RESULT':
			return { ...state, result: action.payload }
		case 'SET_SEARCHING':
			return { ...state, searching: action.payload }
		case 'SET_SUGGESTIONS':
			return { ...state, suggestions: action.payload }
		case 'SET_EVENTS':
			return { ...state, events: action.payload }
		case 'SET_ANNOUNCEMENT':
			return { ...state, announcement: action.payload }
		case 'SET_SHOW_ANNOUNCEMENT':
			return { ...state, showAnnouncement: action.payload }
		default:
			return state
	}
}

export const Context = React.createContext()
