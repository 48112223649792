import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import ContactIcon from '../icons/ContactIcon'

import OutboundLink from '../OutboundLink'

const Contact = ({ name, role, phone, mail }) => (
	<Container>
		<IconContainer>
			<ContactIcon size={18} />
		</IconContainer>
		<Content>
			<Name>{name}</Name>
			<Role>{role}</Role>
			<Link
				target="_self"
				to={`mailto:${mail}`}
				category="Kontaktperson"
				label={`${name} (Mail)`}
				action="Click"
			>
				{mail}
			</Link>
			<Link
				target="_self"
				to={`tel:${phone}`}
				category="Kontaktperson"
				label={`${name} (Telefon)`}
				action="Click"
			>
				+46{phone}
			</Link>
		</Content>
	</Container>
)

const Container = styled.div`
	${tw`bg-white w-full list-none flex px-3 py-3 align-bottom `};
`

const IconContainer = styled.div`
	flex: 0 0 2rem;
	width: 2rem;
	height: 2rem;
	${tw`flex items-center justify-center bg-gray-100 rounded-full overflow-hidden mr-3`};
`

const Content = styled.div`
	${tw`flex flex-col flex-1 truncate`};
`

const Name = styled.span`
	${tw`leading-tight truncate font-semibold mb-1`};
`

const Role = styled.span`
	${tw`text-gray-500 leading-tight text-sm truncate mb-2`};
`

const Link = styled(OutboundLink)`
	${tw`text-black leading-tight text-sm font-medium truncate mb-1 underline`};
`

Contact.propTypes = {
	name: propTypes.string.isRequired,
	role: propTypes.string.isRequired,
	phone: propTypes.string,
	mail: propTypes.string,
}

export default Contact
