import React from 'react'
import propTypes from 'prop-types'

const DownloadIcon = ({ size }) => (
	<svg
		width={size ? size : 16}
		height={size ? size : 16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="text-green-accent fill-current"
	>
		<path d="M8 2C9.19034 2 10.3825 2.45228 11.2907 3.36047C12.0615 4.13126 12.5055 5.1057 12.6221 6.11047C14.5174 6.28254 16 7.87382 16 9.81395C16 11.8689 14.3341 13.5349 12.2791 13.5349H3.16279C1.4106 13.5349 0 12.1243 0 10.3721C0 8.61991 1.4106 7.2093 3.16279 7.2093H3.38372C3.21978 5.84104 3.6651 4.41047 4.71513 3.36047C5.62327 2.45228 6.80966 2 8 2ZM8 5.90698C7.79446 5.90698 7.62791 6.07357 7.62791 6.27907V10.2791L6.2035 8.98255C6.05905 8.85068 5.81261 8.86736 5.67442 9.0058C5.53114 9.1493 5.5579 9.40424 5.70348 9.53488L7.74999 11.3953C7.83191 11.4702 7.90125 11.4887 8 11.4884C8.09952 11.4865 8.19684 11.4441 8.25001 11.3953L10.2965 9.53488C10.4494 9.40179 10.4569 9.15081 10.3256 9.0058C10.1943 8.86082 9.94095 8.85068 9.7965 8.98255L8.37209 10.2791V6.27907C8.37209 6.07357 8.20554 5.90698 8 5.90698Z" />
	</svg>
)

DownloadIcon.propTypes = {
	size: propTypes.number.isRequired,
}

export default DownloadIcon
